import React from 'react'
import Layout from '../components/layout'
import SiteMetaHook from '../hooks/site-meta-hook'

export default () => {
  return (
    <Layout>
      {({ siteMeta }) => {
        return (
          <div>
            {siteMeta.title}
            hello world
          </div>
        )
      }}
    </Layout>
  )
}
